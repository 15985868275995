@media print {
  /* All your print styles go here */
  .print-bottom {
    margin-bottom: 1px !important;
  }
  .printing {
    font-size: 14px;
    background-color: white;
  }
}
